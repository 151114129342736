import "../sass/main.scss";
import { CATEGORIES, ImageLoader, products } from "../constants";
import { useNavigate, Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "../components/Modal";
import { Layout } from "../components/Layout";
import axios from "axios";
import { BASE_URL } from "../constants";
import { LuShoppingBag, LuShoppingCart } from "react-icons/lu";
import { Cart } from "./Cart";
import { AlertNotification } from "../components/AlertNotification";
import { LogInByPhone } from "./LogInByPhone";
import { Spinner } from "../components/Spinner";
export const Dashboard = () => {
    const logged_user = JSON.parse(localStorage.getItem("user"));
    const navigate = useNavigate();
    const ref = useRef();
    const alert = useRef();
    const modal = useRef();
    const confirm = useRef();
    const [pagination, setPagination] = useState({ page: 1, limit: 10 });
    const [items, setItems] = useState([]);
    const [links, setLinks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [toCart, setToCart] = useState({});
    const [cart, setCart] = useState([]);
    const [category, setCategory] = useState('all');
    const [categories, setCategories] = useState([]);

    async function getItems() {
        await axios.get(BASE_URL + `/items?action=orders&category=${category}&page=${pagination.page}&limit=10&status=orders`)
            .then((resp) => {
                setItems(resp.data.data);
                setLinks(resp.data.links);
                getCarts();
                setLoading(false);
            })
            .catch((error) => {
                console.error(error?.message);
            })
    }

    function addCart(product) {
        let data = { ...product, phone: localStorage.getItem("phone") };
        axios.post(BASE_URL + "/carts", data).
            then((resp) => {
                //setLoading(true);
                getCarts();
                getItems();
            }).
            catch((error) => { })
    }

    function getCarts() {
        axios.get(BASE_URL + "/carts?phone=" + localStorage.getItem("phone")).
            then((resp) => {
                setCart(resp.data);
                setToCart({});
            }).
            catch((error) => {

            })
    }

    function getCategories() {
        axios.get(BASE_URL + "/items/categories").
            then((resp) => {
                setCategories(resp.data);
            }).
            catch((error) => {

            })
    }

    function viva() {
        getItems();
    }


    useEffect(() => {
        if (loading) {
            getCategories();
            getItems();
        }
    }, [loading])
    return (
        <Layout viva={viva}>
            <Modal ref={modal} />
            <AlertNotification ref={alert} />
            <div>
                <div className="mb-3">
                    <h1 className="text-slate-800 font-bold font-mono text-[1.5rem]">
                        <div className="text-slate-400">
                            Categories
                        </div>
                    </h1>
                    <div className="flex justify-evenly flex-wrap">
                        {
                            categories.map((_category, index) =>
                                <> <div className={"border border-pink-600 rounded-[20px] " + (_category.category_name == category ? "bg-white" : "bg-pink-600 text-white hover:text-white") + " p-2 my-1  hover:font-bold cursor-pointer"}
                                        onClick={() => {
                                            setCategory(_category.category_name);
                                            setLoading(true);
                                        }}
                                    >{_category.category_label?.toUpperCase()} <span className="border border-slate-400 bg-slate-50 text-slate-800 p-1 px-2 rounded-full">{_category.total}</span></div>
                                </>
                            )
                        }
                    </div>
                </div>
                <div className="text-slate-800 font-bold font-mono text-[1.5rem]">
                    <div className="flex justify-between">
                        <div className="text-slate-400">Products/Bidhaa</div>
                        <div className="text-pink-600">
                            {logged_user?.role == "seller" && <button onClick={() => {
                                navigate("/items");
                            }}>+Items</button>}
                        </div>
                    </div>
                </div>

                {loading ?
                    <div>
                        <Spinner status={"full"} />
                    </div>
                    : <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 gap-[2px] md:gap-4">
                        {items.map((product) =>
                            <div className="bg-white border border-slate-200 md:rounded-lg hover:text-pink-600 shadow-lg w-12/12 justify-center m-1">
                                <span className="font-bold absolute ml-1 mr-1 text-slate-300">{product.batch_no}</span>
                                <img className="object-fill h-48 w-[100%] rounded-lg" src={ImageLoader(product.photo)} loading="lazy" />
                                <div className="grid grid-cols-1 font-mono mb-3">
                                    <span className="flex justify-center text-[13px]"><b>{product.item_name} ({product.uom})  Tsh:{product.price.toLocaleString()}</b></span>
                                    <span className="flex justify-center"><span className="text-slate-500">Stock: </span> {product.remaining_quantity ?? 0}</span>
                                </div>
                                <div className="flex justify-center mb-3">
                                    {
                                        toCart.item_batch_id === product.item_batch_id ?

                                            <span>
                                                <Spinner />
                                            </span> :

                                            ((!cart.filter((cartItem) => {
                                                if (product.item_id == cartItem.item_id && product.item_batch_id == cartItem.item_batch_id) return true;
                                                return false;
                                            }).length) ?

                                                product.remaining_quantity > 0 && product.price > 0 ?

                                                    <button className={"border border-slate-800 hover:border-pink-600 rounded-[4px] bg-slate-700 hover:bg-pink-600 text-white text-[12px] font-mono p-1"} onClick={() => {
                                                        if (!localStorage.getItem("phone")) {
                                                            modal.current.showModal("Login", <LogInByPhone />
                                                                , "w-10/12 sm:w-[100%] md:w-2/12"
                                                            );
                                                            return;
                                                        }
                                                        if (product.remaining_quantity <= 0) {
                                                            alert.current.showAlert(product.item_name + ", Out of stock", "fail");
                                                        }

                                                        setToCart({ ...toCart, item_batch_id: product.item_batch_id });

                                                        !cart.filter((cartItem) => {
                                                            if (product.item_id == cartItem.item_id && product.item_batch_id == cartItem.item_batch_id) return true;
                                                            return false;
                                                        }).length && addCart(product);
                                                    }}>Order Now</button> :

                                                    <span className="text-slate-400">Out Of Stock</span> :

                                                <div className="flex justify-between gap-2 text-pink-600 hover:text-slate-500 cursor-pointer"
                                                    onClick={() => {
                                                        if (!localStorage.getItem("phone")) {
                                                            modal.current.showModal("Login", <LogInByPhone />, "w-10/12 sm:w-[100%] md:w-2/12");
                                                            return;
                                                        }
                                                        modal.current.showModal(<b className="text-pink-600 font-mono">Cart</b>, <Cart cart={cart} viva={viva} />, "w-10/12 sm:w-[100%] md:w-5/12");
                                                    }}
                                                >
                                                    <span>
                                                        <LuShoppingCart size={20} />
                                                    </span>
                                                    <span className=""></span>
                                                </div>
                                            )}
                                </div>
                            </div>
                        )}
                    </div>}
            </div>
        </Layout>
    );
}
